var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'ui search selection dropdown',
    { 'active visible': _vm.isOpen },
    { disabled: _vm.disabled },
  ],attrs:{"id":`custom-dropdown${_vm.identifier}`},on:{"click":_vm.toggleDropdown}},[(_vm.search)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],ref:"input",staticClass:"search",attrs:{"autocomplete":"off","tabindex":"0","placeholder":_vm.placehold},domProps:{"value":(_vm.input)},on:{"input":[function($event){if($event.target.composing)return;_vm.input=$event.target.value},function($event){_vm.isOpen = true}],"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.select(0)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.toggleDropdown(false)}]}}):_vm._e(),(!_vm.input)?_c('div',{staticClass:"default text"},[(Array.isArray(_vm.selected))?_c('div',[(_vm.selected[0])?_c('span',[_vm._v(" "+_vm._s(_vm.selected[0])+" - ")]):_vm._e(),_c('span',{staticClass:"italic"},[_vm._v(_vm._s(_vm.selected[1]))])]):_c('div',[_vm._v(" "+_vm._s(_vm.selectedDisplay)+" ")])]):_vm._e(),_c('i',{class:['dropdown icon', { clear: _vm.clearable && _vm.selected }],attrs:{"aria-hidden":"true"},on:{"click":_vm.clear}}),_c('div',{class:['menu', { 'visible transition': _vm.isOpen }]},_vm._l((_vm.filteredOptions || ['No results found.']),function(option,index){return _c('div',{key:option + index,class:[
        _vm.filteredOptions ? 'item' : 'message',
        { 'active selected': option.name === _vm.selected || option.id === _vm.selected },
      ],attrs:{"id":option.name && Array.isArray(option.name) ? option.name[0] : option.name || option},on:{"click":function($event){return _vm.select(index)}}},[(option.name && Array.isArray(option.name))?_c('div',[(option.name[0])?_c('span',[_vm._v(" "+_vm._s(option.name[0])+" - ")]):_vm._e(),_c('span',{staticClass:"italic"},[_vm._v(_vm._s(option.name[1]))])]):(option.name)?_c('span',[_vm._v(" "+_vm._s(option.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(option)+" ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }