var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"project-members"}},[_c('h1',{staticClass:"ui header"},[_vm._v(" Gérer les membres ")]),_c('h4',[_vm._v("Ajouter un membre")]),_c('div',{staticClass:"ui form",attrs:{"id":"form-feature-edit","name":"add-member"}},[_c('div',{staticClass:"two fields"},[_c('div',{staticClass:"field"},[_c('Dropdown',{attrs:{"options":_vm.userOptions,"selected":_vm.newMember.user.name,"selection":_vm.newMember.user,"search":true,"clearable":true},on:{"update:selection":function($event){return _vm.$set(_vm.newMember, "user", $event)}}}),_c('ul',{staticClass:"errorlist",attrs:{"id":"errorlist"}},_vm._l((_vm.newMember.errors),function(error){return _c('li',{key:error},[_vm._v(" "+_vm._s(error)+" ")])}),0)],1),_c('div',{staticClass:"field"},[_c('Dropdown',{attrs:{"options":_vm.levelOptions,"selected":_vm.newMember.role.name,"selection":_vm.newMember.role},on:{"update:selection":function($event){return _vm.$set(_vm.newMember, "role", $event)}}})],1)]),_c('button',{staticClass:"ui green icon button",attrs:{"type":"button","disabled":!_vm.newMember.user.name},on:{"click":_vm.addMember}},[_c('i',{staticClass:"white add icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"padding-1"},[_vm._v("Ajouter")])])]),_c('h4',[_vm._v("Modifier le rôle d'un membre")]),_c('div',{staticClass:"ui form",attrs:{"id":"form-members"}},[_c('table',{staticClass:"ui red table",attrs:{"aria-describedby":"Table des membres du projet"}},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(" Membre "),_c('i',{staticClass:"icon sort",class:{
                down: _vm.isSortedAsc('member'),
                up: _vm.isSortedDesc('member'),
              },attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.changeSort('member')}}})]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Niveau d'autorisation "),_c('i',{staticClass:"icon sort",class:{
                down: _vm.isSortedAsc('role'),
                up: _vm.isSortedDesc('role'),
              },attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.changeSort('role')}}})])])]),_c('tbody',_vm._l((_vm.projectMembers),function(member){return _c('tr',{key:member.username},[_c('td',[_vm._v(" "+_vm._s(member.user.last_name)+" "+_vm._s(member.user.first_name)),_c('br'),_c('em',[_vm._v(_vm._s(member.user.username))])]),_c('td',[_c('div',{staticClass:"required field online"},[_c('Dropdown',{attrs:{"options":_vm.levelOptions,"selected":member.userLevel.name,"selection":member.userLevel,"search":true},on:{"update:selection":function($event){return _vm.$set(member, "userLevel", $event)}}}),_c('button',{staticClass:"ui icon button button-hover-red",attrs:{"type":"button","data-tooltip":"Retirer ce membre"},on:{"click":function($event){return _vm.removeMember(member)}}},[_c('i',{staticClass:"times icon",attrs:{"aria-hidden":"true"}})])],1)])])}),0)]),_c('div',{staticClass:"ui divider"}),_c('button',{staticClass:"ui teal icon button",attrs:{"type":"button"},on:{"click":_vm.saveMembers}},[_c('i',{staticClass:"white save icon",attrs:{"aria-hidden":"true"}}),_vm._v(" Enregistrer les changements ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }